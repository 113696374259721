// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-en-js": () => import("./../../../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-annulation-paiement-js": () => import("./../../../src/pages/annulation-paiement.js" /* webpackChunkName: "component---src-pages-annulation-paiement-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-erreur-paiement-js": () => import("./../../../src/pages/erreur-paiement.js" /* webpackChunkName: "component---src-pages-erreur-paiement-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-legales-en-js": () => import("./../../../src/pages/mentions-legales.en.js" /* webpackChunkName: "component---src-pages-mentions-legales-en-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-newsletter-en-js": () => import("./../../../src/pages/newsletter.en.js" /* webpackChunkName: "component---src-pages-newsletter-en-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-paiement-en-js": () => import("./../../../src/pages/paiement.en.js" /* webpackChunkName: "component---src-pages-paiement-en-js" */),
  "component---src-pages-paiement-js": () => import("./../../../src/pages/paiement.js" /* webpackChunkName: "component---src-pages-paiement-js" */),
  "component---src-pages-refus-paiement-js": () => import("./../../../src/pages/refus-paiement.js" /* webpackChunkName: "component---src-pages-refus-paiement-js" */),
  "component---src-pages-succes-paiement-js": () => import("./../../../src/pages/succes-paiement.js" /* webpackChunkName: "component---src-pages-succes-paiement-js" */),
  "component---src-pages-ventes-en-js": () => import("./../../../src/pages/ventes.en.js" /* webpackChunkName: "component---src-pages-ventes-en-js" */),
  "component---src-pages-ventes-js": () => import("./../../../src/pages/ventes.js" /* webpackChunkName: "component---src-pages-ventes-js" */)
}

